const newLocal='.v-navigation-drawer__border'
<template>
	<v-navigation-drawer
		:class="{
			'w-navigation-drawer--loading': loading,
			'w-navigation-drawer--resizable': resizable
		}"
		:width="currentWidth"
		v-bind="$attrs"
		v-on="$listeners"
	>
		<div v-show="loading" class="w-navigation-drawer__loader-container">
			<v-progress-circular color="primary" indeterminate size="50" width="6" />
		</div>
		<slot v-bind="{ on: listeners }" :width="currentWidth"></slot>
	</v-navigation-drawer>
</template>

<script>
/**
 * @displayName w-navigation-drawer
 *
 * @since 0.3.2
 */
export default {
	name: 'WNavigationDrawer',
	inheritAttrs: false,
	props: {
		defaultWidth: {
			default: '300px',
			required: false,
			type: String
		},
		minWidth: {
			default: undefined,
			required: false,
			type: String
		},
		resizable: {
			default: false,
			required: false,
			type: Boolean
		}
	},
	data: function () {
		return {
			navDrawerDefaultWidth: undefined,
			navigationDrawerBorderClassname: '.v-navigation-drawer__border',
			loading: false,
			listeners: {
				loaded: this.onLoaded,
				loading: this.onLoading
			},
			resizeObserver: undefined,
			resizedWidth: undefined,
			watchMouseMove: false
		}
	},
	computed: {
		currentWidth: function () {
			let result = null
			if (this.isResized) {
				result = parseInt(this.resizedWidth.replace('px', ''))
			} else {
				if (this.navDrawerDefaultWidth) {
					result = this.navDrawerDefaultWidth
				} else {
					result = this.defaultWidth
				}

				if (this.minWidth) {
					result = parseInt(result.replace('px', ''))
					const minWidth = parseInt(this.minWidth.replace('px', ''))

					if (minWidth > result) {
						result = minWidth
					}
				} else if (typeof result == 'string' && result.endsWith('px')) {
					result = parseInt(result.replace('px', ''))
				}
			}

			this.$emit('update:width', result)
			return result
		},
		isResized: function () {
			if (!this.resizable || !this.resizedWidth) {
				return false
			}

			return this.resizedWidth !== this.defaultWidth
		}
	},
	watch: {
		defaultWidth: {
			handler: 'onWidthUpdated'
		},
		resizable: {
			handler: 'onResizableUpdated'
		}
	},
	mounted: function () {
		this.resizeObserver = new ResizeObserver(this.resizeObserverMethod)

		this.onResizableUpdated(this.resizable)
		this.onWidthUpdated(this.defaultWidth)
	},
	methods: {
		createResizableEvents: function () {
			const border = this.$el.querySelector(this.navigationDrawerBorderClassname)
			border.addEventListener('dblclick', this.resizeDrawerToDefaultWidth)
			border.addEventListener('mousedown', this.onMouseDown)
		},
		deleteResizableEvents: function () {
			const border = this.$el.querySelector(this.navigationDrawerBorderClassname)
			border.removeEventListener('dblclick', this.resizeDrawerToDefaultWidth)
			border.removeEventListener('mousedown', this.onMouseDown)
		},
		onLoaded: function () {
			this.loading = false
		},
		onLoading: function () {
			this.loading = true
		},
		onMouseDown: function (event) {
			const resize = function (event) {
				this.resizedWidth = `${document.body.scrollWidth - event.clientX}px`
				this.$el.style.width = this.resizedWidth
			}.bind(this)

			const border = this.$el.querySelector('.v-navigation-drawer__border')
			const borderSize = border.offsetWidth

			if (event.offsetX < borderSize) {
				document.addEventListener('mousemove', resize)
			}

			document.addEventListener('mouseup', () => {
				document.removeEventListener('mousemove', resize)
			})
		},
		onResizableUpdated: function (newVal) {
			if (newVal) {
				this.createResizableEvents()
			} else {
				this.deleteResizableEvents()
			}
		},
		onWidthUpdated: function (newVal) {
			if (!newVal || typeof newVal === 'number' || !newVal.endsWith('%')) {
				this.navDrawerDefaultWidth = undefined
				this.resizeObserver.disconnect()
				return
			}

			this.resizeObserver.observe(this.$parent.$el)
		},
		resizeDrawerToDefaultWidth: function () {
			this.$el.style.width = this.defaultWidth
		},
		// eslint-disable-next-line no-unused-vars
		resizeObserverMethod: function ([entry, ...rest]) {
			const innerWidth = entry.contentRect.width
			if (this.resizable && this.isResized && this.oldInnerWidth) {
				const ratio = parseInt(this.resizedWidth.substring(0, this.resizedWidth.length - 2)) / this.oldInnerWidth

				this.resizedWidth = `${innerWidth * ratio}px`
				this.$el.style.width = this.resizedWidth
			} else if (typeof this.defaultWidth === 'string' && this.defaultWidth.endsWith('%')) {
				const ratio = parseInt(this.defaultWidth.substring(0, this.defaultWidth.length - 1))

				if (!isNaN(ratio)) {
					this.navDrawerDefaultWidth = `${innerWidth * (ratio / 100)}px`
				}
			}

			this.oldInnerWidth = innerWidth
		}
	}
}
</script>

<style scoped>
.v-navigation-drawer.w-navigation-drawer--resizable {
	transition: none;
}

.v-navigation-drawer.w-navigation-drawer--resizable :deep(.v-navigation-drawer__border) {
	cursor: ew-resize;
	user-select: none;
	width: 4px;
	z-index: 1;
}

.v-navigation-drawer.w-navigation-drawer--loading :deep(.v-navigation-drawer__border) {
	background-color: rgba(0, 0, 0, 0);
}

div.w-navigation-drawer__loader-container {
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	height: 100%;
	justify-content: center;
	position: absolute;
	width: 100%;
	z-index: 200;
}
</style>
